import { createSlice } from '@reduxjs/toolkit';

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    error: null,
    orderData: null,
    orderItems: [],
    temporaryItems: [],
    newOrder: []
  },
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    setOrderDataSuccess(state, action) {
      state.orderData = action.payload;
    },
    setOrderItemsSuccess(state, action) {
      state.orderItems = action.payload;
    },
    // addOrderItemSuccess(state, action) {
    //   console.log(action.payload)
    //   const newProduct = action.payload;
    //   const isProductExist = state.orderItems.some(
    //     item => item.store_sku_id === newProduct.store_sku_id && state.orderData?.order_id === Number(newProduct.order_id)
    //   );
    //   if (!isProductExist && Number(newProduct.order_id) === state.orderData?.order_id) {
    //     state.orderItems.push(newProduct);
    //   }
    // },
    addOrderItemSuccess(state, action) {
      const newProduct = action.payload;
      const isProductExist = state.orderItems.some(
        item => item.store_sku_id === newProduct.store_sku_id && state.orderData?.order_id === Number(newProduct.order_id)
      );
      if (!isProductExist && Number(newProduct.order_id) === state.orderData?.order_id) {
      state.temporaryItems.push(newProduct);
      }
    },
    updateOrderItemQuantitySuccess(state, action) {
      const { index, qty } = action.payload;
      if (state.orderItems[index]) {
        state.orderItems[index].qty = qty;
      }
    },
    removeOrderItemSuccess(state, action) {
      const { store_sku_id, order_id } = action.payload;
      if (Number(order_id) === state.orderData?.order_id) {
        state.orderItems = state.orderItems.filter(
          item => item.store_sku_id !== store_sku_id
        );
        state.temporaryItems = state.temporaryItems.filter(
          item => item.store_sku_id !== store_sku_id
        );
      }
    },
    updateTotalAmountSuccess(state) {
      const totalAmount = state.orderItems.reduce((total, item) => total + item.qty * item.discount_price, 0);
      const subtotal = state.orderItems.reduce((total, item) => total + item.qty * item.price, 0); 
      const totalDiscount = subtotal - totalAmount;
      state.orderData = {
        ...state.orderData,
        total_amount: totalAmount,
        total_discount: totalDiscount,
        subtotal: subtotal,
        total_qty: state.orderItems.reduce((total, item) => total + item.qty, 0)
      };
    },
    clearOrderData(state) {
      state.orderData = null;
    },
    clearOrderItems(state) {
      state.temporaryItems = [];
    },

    // New Order section
    addNewOrderSuccess(state, action) {
      const newProduct = action.payload;
      const existingProduct = state.newOrder.find(
        item => item.store_sku_id === newProduct.store_sku_id
      );
      if (existingProduct) {
        existingProduct.qty += newProduct.qty;
      } else {
        state.newOrder.push(newProduct);
      }
    },
    removeNewOrder(state, action) {
      const productToRemove = action.payload;
    
      const existingProductIndex = state.newOrder.findIndex(
        item => item.store_sku_id === productToRemove.store_sku_id
      );
    
      if (existingProductIndex !== -1) {
        const existingProduct = state.newOrder[existingProductIndex];
        if (existingProduct.qty > productToRemove.qty) {
          existingProduct.qty -= productToRemove.qty;
        } else {
          state.newOrder.splice(existingProductIndex, 1);
        }
      }
    },
    updateOrderQty(state, action) {
      const { store_sku_id, qty } = action.payload;

      const existingProduct = state.newOrder.find(
        item => item.store_sku_id === store_sku_id
      );
    
      if (existingProduct && qty > 0) {
        existingProduct.qty = qty;
      }
    },
    clearNewOrder(state) {
      state.newOrder = [];
    },
  },
});

export const {
  hasError,
  setOrderDataSuccess,
  setOrderItemsSuccess,
  addOrderItemSuccess,
  updateOrderItemQuantitySuccess,
  removeOrderItemSuccess,
  updateTotalAmountSuccess,
  clearOrderData,  
  clearOrderItems,
  addNewOrderSuccess,
  removeNewOrder,
  updateOrderQty,
  clearNewOrder
} = orderSlice.actions;

export default orderSlice.reducer;
