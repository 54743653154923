import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
// import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
// import { REGISTER, LOGIN, LOGOUT } from 'store/reducers/actions';
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project-imports
import Loader from 'components/Loader';
import axios from 'utils/axios';

// const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

// const verifyToken = (serviceToken) => {
//   if (!serviceToken) {
//     return false;
//   }
//   const decoded = jwtDecode(serviceToken);

//   /**
//    * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
//    */
//   return decoded.exp > Date.now() / 1000;
// };

const verifyToken = (refreshToken) => {
  if (!refreshToken) {
    return false;
  }

  try {
    const decoded = jwtDecode(refreshToken);
    if (decoded.exp <= Date.now() / 1000) {
      return false;
    }
    return true;
  } catch (error) {
    console.error('Error decoding refresh token:', error);
    return false;
  }
};

const setSession = (serviceToken, refreshToken, storeName, storeCity, storeMobile) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('storeName', storeName);
    localStorage.setItem('storeCity', storeCity);
    localStorage.setItem('storeMobile', storeMobile);
    // axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    axios.defaults.headers.common.Authorization = serviceToken;
    axios.defaults.headers.common.refreshToken = refreshToken;
  } else {
    localStorage.removeItem('serviceToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('storeName');
    localStorage.removeItem('storeCity');
    localStorage.removeItem('storeMobile');
    delete axios.defaults.headers.common.Authorization;
    delete axios.defaults.headers.common.refreshToken;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = localStorage.getItem('serviceToken');
        const refreshToken = localStorage.getItem('refreshToken');
        const storeName = localStorage.getItem('storeName');
        const storeCity = localStorage.getItem('storeCity');
        const storeMobile = localStorage.getItem('storeMobile');
        if (refreshToken && verifyToken(refreshToken)) {
          setSession(serviceToken, refreshToken, storeName, storeCity, storeMobile);
          // const response = await axios.get('/api/account/me');
          // const { user } = response.data;
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true
              // user
            }
          });
        } else {
          await logout();
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async (mobile, password) => {
    // const response = await axios.post('/api/account/login', { email, password });
    const response = await axios.post('/store/login', { mobile, password });
    // const { serviceToken, user } = response.data;
    const user = {};
    user.id = response.data.result.data.id;
    user.store = response.data.result.data.store_name;
    user.city = response.data.result.data.city;
    user.mobile = response.data.result.data.mobile_number;
    localStorage.setItem('storeId', user.id);

    const serviceToken = response.data.result.token;
    const refreshToken = response.data.result.refreshToken;
    setSession(serviceToken, refreshToken, user.store, user.city, user.mobile);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user
      }
    });
  };

  // const register = async (first_name, last_name, company_name, gst_no, product_info, email, mobile_no, city, pincode) => {
  //     const response = await axios.post('/vendor/register', {
  //       first_name,
  //       last_name,
  //       company_name,
  //       gst_no,
  //       product_info,
  //       email,
  //       mobile_no,
  //       city,
  //       pincode
  //     });
  //     let users = response.data;
  //     if (response) {
  //       dispatch({
  //         type: REGISTER,
  //         payload: {
  //           isRegistered: true
  //           // user
  //         }
  //       });
  //   }

  
      // Update the local storage if needed
      // if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
      //   const localUsers = window.localStorage.getItem('users');
      //   users = [
      //     ...JSON.parse(localUsers),
      //     {
      //       id: chance.bb_pin(),
      //       email,
      //       password,
      //       name: `${firstName} ${lastName}`
      //     }
      //   ];
      // }
  
  //     window.localStorage.setItem('users', JSON.stringify(users));
  // };
  

  const logout = async () => {
   const storeId = localStorage.getItem('storeId');
   try {
     await axios.post('/store/logout', { id: storeId });
   } catch (error) {
     console.error('Logout API error:', error);
   } finally {
     setSession(null);
     localStorage.removeItem('storeId');
     dispatch({ type: LOGOUT });
   }
 };
  const resetPassword = async () => {};

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  // return <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>;
  return <JWTContext.Provider value={{ ...state, login, logout, resetPassword, updateProfile }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;
